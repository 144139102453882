<template>
  <div class="teacher_detail_content">
    <div class="page-top-bg"></div>

    <div class="page-top-bg-2">
      <icon name="bg-dot-circle" class="pc-bg2"></icon>
      <icon name="bg-dot-circle" class="sp-bg2"></icon>
    </div>

    <div class="content-body">
      <commonTitle
        title="TEACHER"
        subTitle="講師"
        class="title"
      ></commonTitle>

      <template v-if="viewState.isDisplayed">
        <teacherDetail
          :instructor="viewState.teacher"
          class="teacher-section"
        />

        <div class="related-videos">
          <div class="related-videos-top-bg"></div>
          <div class="related-videos-bottom-bg"></div>
          <div class="related-videos-title">LESSONS</div>
          <div class="related-videos-subtitle">担当講座</div>
          <div class="swiper-button-prev">
            <icon
              name="swiper-button-prev-btn"
              class="swiper-button-prev-btn-icon"
            ></icon>
          </div>
          <div class="swiper-button-next">
            <icon
              name="swiper-button-next-btn"
              class="swiper-button-next-btn-icon"
            ></icon>
          </div>
          <div class="swiper-index">
            <div class="swiper-index-page-text">Page&nbsp;</div>
            {{ viewState.realIndex }}/{{ viewState.videosCount }}
          </div>
          <swiper
            class="swiper-container"
            :loop="viewState.videosCount > 1"
            :slidesPerView="4"
            :slidesPerGroup="4"
            :breakpoints="breakpoints"
            :navigation="{
              prevEl: '.swiper-button-prev',
              nextEl: '.swiper-button-next',
            }"
            @activeIndexChange="activeIndexChange"
          >
            <swiper-slide
              v-for="(x, index) in viewState.videosCount"
              :key="index"
              :class="'related-videos-items'"
            >
              <relatedVideosItem
                :imgIndex="index % 4"
                :class="(index + 1) % 2 == 0 ? 'related-videos-item-mt110' : ''"
                :video="viewState.teacher.lectures[index]"
              ></relatedVideosItem>
            </swiper-slide>
          </swiper>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import util from "@/utils/util";
import http from "@/utils/http";
import { defineComponent, reactive, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import teacherDetail from "./components/TeacherDetail.vue";
import commonTitle from "@/components/CommonTitle/CommonTitle.vue";
import relatedVideosItem from "@/components/CommonRelatedVideos/RelatedVideosItem.vue";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.scss";
SwiperCore.use([Navigation]);

export default defineComponent({
  components: {
    teacherDetail,
    commonTitle,
    relatedVideosItem,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const viewState = reactive({
      teacher: {},
      teacherCode: location.pathname.split("/").slice(-1)[0],
      videosCount: 1,
      realIndex: 1,
      isDisplayed: false,
    });

    const isSp = computed(() => store.state.isSp);
    const isEnterprise = computed(() => store.state.user.isEnterprise);
    const breakpoints = {
      0: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 0,
      },
      375: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 0,
      },
      600: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 0,
      },
      766: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 20,
      },
      1036: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 20,
      },
      1366: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 20,
      },
    };

    window.scrollTo(0, 0);

    // method
    const fetchInstructor = () => {
      return http
        .get(`/api/instructors/${viewState.teacherCode}?key_type=code`)
        .then((response) => {
          console.dir(response)
          viewState.teacher = response.data;
          viewState.videosCount = viewState.teacher.lectures.length;
          
          return response;
        })
        .catch((error) => {
          return error.response;
        });
    };

    const activeIndexChange = ({ realIndex }) => {
      viewState.realIndex = realIndex + 1;
    };

    onMounted(async () => {
      if (isEnterprise.value) {
        router.push("/");
        return;
      }

      await fetchInstructor();
      viewState.isDisplayed = true; // 表示OK
    });
    watch(
      () => store.state.user.isEnterprise,
      () => {
        if (isEnterprise.value) {
          router.push("/");
          return;
        }
      }
    );

    return {
      util,
      viewState,
      isSp,
      isEnterprise,
      breakpoints,

      // method
      activeIndexChange,
    };
  },
});
</script>

<style lang="scss" scoped>
.teacher_detail_content {
  max-width: 1366px;
  position: relative;
  margin: 0 auto 115px;

  @include mq(sm) {
    margin-bottom: 80px;
  }

  .page-top-bg {
    max-width: 1368px;
    width: 1368px;
    min-height: 50px;
    background-image: url("../../../assets/img/top-login-top-bg.png");
    background-size: 100%;
    z-index: 1;
    @include mq(sm) {
      background-image: url("../../../assets/img/sp-top-bg.png");
    }
  }

  .page-top-bg-2 {
    position: absolute;
    width: 620px;
    height: 620px;
    left: -310px;
    top: -280px;
    z-index: -1;

    @include mq(sm) {
      left: -141px;
      top: -91px;
    }

    .pc-bg2 {
      min-width: 620px;
      min-height: 620px;
      @include mq(sm) {
        display: none;
      }
    }

    .sp-bg2 {
      display: none;
      @include mq(sm) {
        display: block;
        width: 282px;
        height: 282px;
      }
    }
  }

  .content-body {
    margin: 0 50px;

    @include mq(sm) {
      margin: 0 20px;
    }

    .title {
      margin-top: 65px;
    }

    .teacher-section {
      margin: 40px 0 0 65px;
      max-width: 1139px;
      @include mq(sm) {
        margin-left: 20px;
        margin-top: 60px;
        margin-right: 20px;
      }
    }

    .related-videos {
      max-width: 1266px;
      height: 882px;
      margin-top: 80px;
      margin-bottom: 50px;
      background: rgba(253, 255, 255, 1);
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include mq(sm) {
        margin-top: 60px;
        margin-bottom: 20px;
        height: auto;
        padding-bottom: 60px;
      }
      &::before {
        content: "";
        position: absolute;
        width: 50%;
        height: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        background: $white;
        box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
        z-index: -1;
      }
      .related-videos-top-bg {
        position: absolute;
        width: 584px;
        height: 584px;
        background: $primary;
        opacity: 0.8;
        border-radius: 50%;
        right: -292px;
        top: -292px;
        z-index: -1;
        @include mq(sm) {
          width: 255px;
          height: 255px;
          right: -147px;
          top: -128px;
        }
      }
      .related-videos-bottom-bg {
        position: absolute;
        width: 584px;
        height: 584px;
        background: $secondary;
        opacity: 0.4;
        border-radius: 50%;
        bottom: -342px;
        left: -292px;
        z-index: -1;
        @include mq(sm) {
          width: 255px;
          height: 255px;
          bottom: -117px;
          left: -128px;
        }
      }
      .swiper-button-prev {
        z-index: 100;
        cursor: pointer;
        position: absolute;
        right: 125px;
        top: 147px;
        .swiper-button-prev-btn-icon {
          width: 40px;
          height: 40px;
        }
        @include mq(sm) {
          position: relative;
          right: unset;
          top: 30px;
          left: 30px;
          .swiper-button-prev-btn-icon {
            width: 30px;
            height: 30px;
          }
        }
      }
      .swiper-button-next {
        z-index: 100;
        cursor: pointer;
        position: absolute;
        right: 65px;
        top: 147px;
        .swiper-button-next-btn-icon {
          width: 40px;
          height: 40px;
        }
        @include mq(sm) {
          position: relative;
          right: unset;
          top: 0;
          left: 70px;
          .swiper-button-next-btn-icon {
            width: 30px;
            height: 30px;
          }
        }
      }
      .swiper-index {
        display: none;

        @media screen and (max-width: 765px) {
          display: flex;
          width: 88px;
          height: 15px;
          align-items: baseline;
          position: relative;
          top: -24px;
          width: 100%;
          font-weight: 700;
          font-size: 14px;
          line-height: 100%;
          letter-spacing: 0.08em;
          color: $black-50;
          justify-content: flex-end;
          padding-right: 30px;
        }
      }
      .related-videos-title {
        margin-left: 65px;
        margin-top: 45px;
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 700;
        font-size: 40px;
        line-height: 60px;
        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
        color: $black;
        height: 100px;
        position: relative;
        user-select: none;
        -webkit-user-select: none;
        &::before {
          position: absolute;
          content: "";
          width: 40px;
          height: 0px;
          left: 0;
          bottom: 16px;
          border-bottom: 4px solid $black;
        }
        @include mq(sm) {
          margin-left: 30px;
          margin-top: 60px;
          font-family: "Roboto Condensed", sans-serif;
          font-weight: 700;
          font-size: 30px;
          line-height: 100%;
          display: flex;
          align-items: center;
          letter-spacing: 0.08em;
          color: $black;
          height: unset;
          &::before {
            left: 0;
            bottom: -12px;
          }
        }
      }
      .related-videos-subtitle {
        margin-left: 65px;
        margin-top: 4px;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: 0.08em;
        color: $black;
        opacity: 0.5;
        user-select: none;
        -webkit-user-select: none;
        @include mq(sm) {
          margin-left: 30px;
          margin-top: 30px;
          font-weight: 500;
          font-size: 18px;
          line-height: 100%;
          letter-spacing: 0.08em;
          color: $black;
        }
      }
      .swiper-container {
        margin-left: 65px;
        margin-right: 65px;
        width: calc(100% - 130px);

        .related-videos-items {
          height: 715px;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          .related-videos-item-mt110 {
            margin-top: 110px;
          }
        }

        @include mq(sm) {
          margin-top: 15px;
          margin-left: 30px;
          margin-right: 30px;
          width: 275px;
          .related-videos-items {
            height: auto;
            .related-videos-item-mt110 {
              margin-top: 0px;
            }
          }
        }
      }
    }
  }
}
</style>
